<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <b-row class="mb-2">
                <b-col md="4">
                    <h2><strong>List of Subjects</strong></h2>
                </b-col>
            </b-row>

            <div class="card">
                <div class="card-body">
                    <div class="row my-1">
                        <div class="col-md-6 col-12">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                            class="my-1"
                            ></b-form-input>
                        </div>

                        <div class="col-md-6 col-12 mt-1">
                            <b-button variant="outline-primary" size="sm" @click="newModal" class="ml-1">
                                <i class="fa fa-plus"></i>  Add Subject
                            </b-button>
                        
                            <b-button variant="outline-danger" size="sm" class="ml-1" v-if="action.selected.length" @click="onDeleteAll"><i class="fa fa-trash"></i> Delete Selected</b-button>

                            <b-button disabled size="sm" variant="outline-danger" v-else class="ml-1"> <i class="fa fa-trash"></i> Delete Selected</b-button>                      
                        </div>
                    </div>
                 
                    <b-table
                        responsive
                        :items="items"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :bordered="bordered" 
                        :hover="hover"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        show-empty
                        id="my-table"
                        small
                        @filtered="onFiltered"
                    >   
                        <template #head(index)="">
                            <input type="checkbox" v-model="selectAll">
                        </template>

                        <template #cell(actions)="room">
                            <b-dropdown id="dropdown-right" text="Action" variant="info" size="sm">
                                <b-dropdown-item href="javascript:void(0)" @click="editModal(room.item)">Edit</b-dropdown-item>
                                <b-dropdown-item href="javascript:void(0)" @click="onDeleteAll(room.item.id)">Delete</b-dropdown-item>
                          </b-dropdown>
                        </template>

                        <template #cell(index)="data">
                            <!--{{ data.index + 1 }}-->
                            <input type="checkbox" v-model="action.selected" :value="data.item.id" number>
                        </template>
                    </b-table>
                </div>

                <div class="card-footer">
                    <b-row class="mb-2">
                        <b-col md="4">
                            <b-form-group
                                label="Showing"
                                label-for="per-page-select"
                                label-cols-md="4"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                                class="def"
                                ></b-form-select>
                                 of {{ items.length }} Items
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-pagination
                                aria-controls="my-table"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <b-modal ref="addNew" :title="title" hide-footer>
                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="form-content">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                </form>
            </b-modal>
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    import { Form } from 'vform';
    
    export default {
        data() {
            return {
                is_busy: false,
                editMode: false,
                title: '',
                items: [],
                fields: [
                    { key: 'index', label: '' },
                    { key: 'name', label: 'Name'},
                    { key: 'actions', label: 'Actions' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 15,
                bordered: true,
                hover: true,
                fixed: true,
                pageOptions: [5, 10, 15, 25, 50, 100, { value: 100, text: "All" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },

                is_busy: false,
                editmode: false,
              
                action: {
                    selected: [],
                    id: '',
                },

                form: new Form({
                    id : '',
                    name: null,
                }),
            }
        },

        created() {
            this.roleHelper(['1', '2', '5']);
            this.load();
        },

        methods: {
            load() {
                if(this.is_busy) return;
                this.is_busy = true;
                axios.get('/subject')
                .then(res => {
                    this.items = res.data.data;
                    this.totalRows = this.items.length
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },

            newModal(){
                this.editmode = false;
                this.form.reset();
                this.title = 'Add Subject';
                this.$refs['addNew'].show()
            },

            createUser(){
                this.$refs['addNew'].hide()
                this.form.post('/subject')
                .then((response)=>{ 
                    Swal.fire(
                        "Created!",
                        response.data.message,
                        "success"
                    );
                    this.load();
                })
                .catch((err)=>{
                    Swal.fire(
                        "Error!",
                        err.response.data.data.error,
                        "error"
                    );
                });
                this.load();
            },

            editModal(subject){
                this.editmode = true;
                this.form.reset();
                this.title = 'Edit Subject';
                this.form.fill(subject);
                this.$refs['addNew'].show()
            },

            updateUser(){
                this.$refs['addNew'].hide()
                this.form.put('/subject/'+this.form.id)
                .then((response)=>{ 
                    Swal.fire(
                        "Created!",
                        response.data.message,
                        "success"
                    );
                    this.load();
                })
                .catch((err)=>{
                    Swal.fire(
                        "Error!",
                        err.response.data.data.error,
                        "error"
                    );
                });
                this.load();
            },

            onDeleteAll(id) {
                if(id){
                    this.action.selected.push(id);
                }
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!, Deleting these subjects will wipe out all related data",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete!"
                })
                .then(result => {
                    if (result.value) {
                    axios.get('/subject/delete', { params: this.action})
                    .then(() => {
                        Swal.fire(
                            "Deleted!",
                            "Subject(s) deleted.",
                            "success"
                        );
                        this.load();
                    })

                    .catch(() => {
                        Swal.fire(
                            "Failed!",
                            "Ops, Something went wrong, try again.",
                            "warning"
                        );
                    });
                    }
                });
                this.load();
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },

        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                .filter(f => f.sortable)
                .map(f => {
                return { text: f.label, value: f.key }
                })
            },

            selectAll: {
                get: function () {
                    return this.items ? this.action.selected.length == this.items.length : false;
                },

                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.items.forEach(function (room) {
                            selected.push(room.id);
                        });
                    }

                    this.action.selected = selected;
                }
            }
        },
    }
</script>
<style>
    .cont {
        background: #F3F2F7;
    }

    .def {
        width: 70px;
    }
</style>